
































import Vue from 'vue';
import { Component } from 'vue-property-decorator';
import { CompanyExt, Office } from '@/models';
import { AccountantService } from '@/lib/services';
import { unwrapError } from '@/lib/helpers';

import CompanyLoginList from '@/components/CompanyLoginList.vue';
import CompanyEditModal from '@/components/modals/CompanyEditModal.vue';
import Fab from '@/components/Fab.vue';

@Component({
  components: {
    CompanyLoginList,
    CompanyEditModal,
    Fab,
  },
})
export default class AccountantCompaniesPage extends Vue {
  loading: boolean = true;
  companies: CompanyExt[] = [];
  offices: Office[] = [];

  editingCompany: CompanyExt | null = null;
  newCompany: CompanyExt | null = null;

  async created(): Promise<void> {
    await this.refresh();
  }

  async refresh(): Promise<void> {
    this.loading = true;
    try {
      this.companies = await AccountantService.getCompanies();
      this.offices = await AccountantService.getOffices();
    } catch (e) {
      this.$toaster.error('Error loading companies', unwrapError(e));
    } finally {
      this.loading = false;
    }
  }

  onEdit(c: CompanyExt): void {
    this.editingCompany = c;
  }

  startNewCompany(): void {
    this.newCompany = new CompanyExt();
  }

  async onNewComplete(c: CompanyExt): Promise<void> {
    try {
      await AccountantService.createCompany(c);
      this.$toaster.success(
        this.$tc('messages.success.created.company'),
        c.name,
      );
      this.newCompany = null;
      await this.refresh();
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.create.company'),
        unwrapError(e),
      );
    }
  }

  async onEditComplete(c: CompanyExt): Promise<void> {
    try {
      await AccountantService.updateCompany(c);
      this.$toaster.success(
        this.$tc('messages.success.update.company'),
        c.name,
      );
      this.editingCompany = null;
      await this.refresh();
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.update.company'),
        unwrapError(e),
      );
    }
  }

  async resetCompany(c: CompanyExt): Promise<void> {
    try {
      await AccountantService.resetCompany(c.id);
      this.$toaster.success(this.$tc('messages.success.reset.company'), c.name);
      this.editingCompany = null;
      await this.refresh();
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.reset.company'),
        unwrapError(e),
      );
    }
  }

  async detachCompany(c: CompanyExt): Promise<void> {
    try {
      await AccountantService.detachCompany(c.id);
      this.$toaster.success(
        this.$tc('messages.success.detach.company'),
        c.name,
      );
      this.editingCompany = null;
      await this.refresh();
    } catch (e) {
      this.$toaster.error(
        this.$tc('messages.error.detach.company'),
        unwrapError(e),
      );
    }
  }
}
